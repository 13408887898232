import { useEffect, useState } from "react";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const newData = {};

    queryParams.forEach((value, key) => {
      newData[key] = value;
    });

    setData(newData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading && Object.keys(data).length > 0) {
      fetch("https://demoelitchem.shrinesoft.com/generate-label", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.blob())
        .then((blob) => {
          if (blob.type === "application/pdf") {
            const fileURL = URL.createObjectURL(blob);
            // window.open(fileURL);
            window.location.replace(fileURL);
          } else {
            console.error("Received blob is not a PDF.");
          }
        })
        .catch((e) => console.error(e));
    }
  }, [loading, data]);

  return null;
};

export default App;
